<template>
  <div class="expertDetails">
    <div class="bigImg">
    </div>
    <div class="Details">
      <h2>{{data.Title}}</h2>
      <div class="timeSource">
        <label>{{data.NewsTime}}</label>
        <p>
          <span>来源：</span>
          <span>{{data.Source}}</span>
        </p>
      </div>
      <hr>
      <div class="zhaiyao">
        摘要：{{data.Synopsis}}
      </div>
      <div class="NewsContext" v-html="data.NewsContext"></div>
      <p class="WriterName">编辑/{{data.WriterName}}</p>
    </div>
  </div>
</template>

<script>
import {expertDetails} from '../../../api/info';
export default {
  name:'expertDetails',
  data(){
    return{
      // 父级页面传来的参数
      query:{
        id:'',
      },
      // 获取到的数据
      data:{

      },
    }
  },
  created(){
    // 传参控制显示顶部和底部
    this.$emit('controlShow', false);
    // 获取参数
    this.query.id = this.$route.query.id;
    // 初始化数据
    this.init(this.query);
  },
  methods:{
    init(attr){
      expertDetails(attr).then(res=>{
        if(res.ok == 1){
          this.data = res.data;
        }
      }).catch(err=>{
        console.log(err);
      })
    }
  }
}
</script>

<style lang="less">
.expertDetails{
  width: 100%;
  .bigImg{
    width: 100%;
    height: 600px;
    background: url('../../../static/market/banner.png') no-repeat;
    background-size: cover;
  }
  .Details{
    width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 50px 0 70px;
    .timeSource{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      label{
        color: rgb(129, 129, 128);
        line-height: 21px;
      }
      p{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 30px;
        line-height: 21px;
        span:nth-child(1){
          color: rgb(129, 129, 128);
        }
      }
    }
    hr{
      width: 100%;
      margin: 0 auto;
    }
    .zhaiyao{
      background-color: #f3f5fb;
      box-sizing: border-box;
      padding: 40px;
      margin: 20px 0;
    }
    .WriterName{
      width: 100%;
      margin-top: 30px;
    }
    // .NewsContext{
    //   p{
    //     img{
    //       text-align: center !important;
    //     }
    //   }
    // }
  }
}
@media screen and (max-width: 500px) {
  .expertDetails{
    width: 100%;
    .bigImg {
      width: 100%;
      height: 450px;
      background: url('../../../static/index/wapImg/maketBanner.jpg') no-repeat;
      background-size: cover;
    }
    .Details{
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 20px 0 30px;
      h2{
        font-size: 18px;
        text-align: center;
      }
      .timeSource{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        label{
          color: rgb(129, 129, 128);
          line-height: 21px;
          font-size: 12px;
        }
        p{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 20px;
          line-height: 21px;
          font-size: 12px;
          span:nth-child(1){
            color: rgb(129, 129, 128);
          }
        }
      }
      hr{
        width: 95%;
        margin: 0 auto;
      }
      .zhaiyao{
        width: 95%;
        background-color: #f3f5fb;
        box-sizing: border-box;
        padding: 20px;
        margin: 20px auto;
        font-size: 14px;
      }
      .NewsContext{
        width: 95%;
        margin: 0 auto;
        p{
          font-size: 14px;
          img{
            width: 100% !important;
          }
        }
      }
      .WriterName{
        width: 95%;
        margin: 20px auto 0;
        font-size: 14px;
      }
    }
  }    
}
</style>